<template>
  <div>
    <v-container fluid class="pa-12 ">
      <v-card>
        <v-card-text>
          <v-tabs v-model="currentItem" >
            <v-tab v-for="canal in canaux"
            :key="canal">{{ $t(canal) }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="currentItem">
            <v-tab-item
              class="pa-6 pb-0"
              v-for="canal in canaux"
              :key="canal"
            >
              <DetailStatCanalComponent :canal="canal" />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
      
    </v-container>
  </div>
</template>
<script>
import vDaterangePicker from '@/components/forms/inputs/v-daterange-picker.vue'
import DatatableV2 from '@/components/DatatableV2'
import DetailStatCanalComponent from '../components/detailStatCanalComponent.vue';

export default {
  name: "StatisticsList",
  components: {
    vDaterangePicker, DatatableV2, DetailStatCanalComponent
  },
  data() {
    return {
      currentItem:0,
      parents: [],
      filtersMarketing: {},
      stats: {},
      currentKey: 0,
      statKey: 0,
      apiUrl: "/stats/list?canal=EMAIL",
      loading: false,
      downloadUrl: '',
      api: this.$store.state.auth.apiUrl,
      showDownload: false,
      canaux: ['EMAIL', 'SMS']
    };
  },
  created() {
  },
  methods: {
  
  },
  computed: {
    
  },
  mounted() {
    
  },
};
</script>
