<template>
<v-row class="pb-6">
    <v-col cols="12">
        <v-row class="pa-4 pb-0">
            <v-col cols="10">
                <v-row>
                <template v-for="(filter, index) in filtersMarketing">
                    <vDaterangePicker v-if="filter.type == 'dateperiod'" mode="past" v-model="filter.value" @change="reloadData" class="mr-6" :label="index" :key="'filter_'+index"/>
                    <v-select v-else-if="filter.type == 'select'" :no-data-text="$t('noData')" multiple v-model="filter.value" class="mr-6 mt-0 pt-0"  @change="reloadData" :items="filter.items" style="max-width:200px;" :label="$t(index)" :key="'filterSelect_'+index"/>
                </template>
                </v-row>
            </v-col>
            <v-col cols="2" class="pt-0 text-right" v-if="canal != 'EMAIL'">
                <v-btn color="primary" small @click="launchExport" :loading="loading">{{ $t('exportStat') }}</v-btn>
            </v-col>
            <v-col cols="2" class="pt-0 text-right" v-else>
                <v-menu
                bottom
                :offset-y="true"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    color="primary"
                    small
                    v-bind="attrs"
                    v-on="on"
                    >
                    Exports
                    </v-btn>
                </template>

                <v-list dense>
                    <v-list-item @click="launchExport">
                    <v-list-item-title >{{ $t('exportStat') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="launchExportSpec('openners')">
                    <v-list-item-title >{{ $t('exportStatOpenners') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="launchExportSpec('clickers')">
                    <v-list-item-title >{{ $t('exportStatClickers') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="launchExportSpec('bouncers')">
                    <v-list-item-title >{{ $t('exportStatBouncers') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
                </v-menu>
            </v-col>
        </v-row>
    </v-col>
    <v-col cols="12" v-for="(stat, index) in stats" :key="'stats_'+statKey+'_'+index" class="pt-6">
        <v-card>
        <v-card-title>
            <h5 class="primary--text">{{ $t(index) }}</h5>
        </v-card-title>
        <v-card-text>
            <div class="d-flex justify-space-around px-12 mx-6 pb-6">
            <div v-for="(substat, index) in stat" class="d-flex flex-column align-center justify-center">
                <v-icon large color="primary">
                {{ substat.icon }}
                </v-icon>
                <h2 class="mb-0">{{ substat.value }}</h2>
                <p class="mt-0 mb-1">{{ $t(substat.label) }}</p>
                <v-chip outlined small :color="substat.color">{{ substat.percent }}</v-chip>
            </div>
            </div>
        </v-card-text>
        </v-card>
    </v-col>
    <v-col cols="12">
        <v-dialog persistent width="400" v-model="showDownload">
        <v-card>
            <v-card-text class="pa-6">
                <h5 class="icon-title">
                {{$t('downloadReady')}}
                <v-btn @click="showDownload = false" text class="square">
                    <v-icon small>$close</v-icon>
                </v-btn>
                </h5>
                <div class="text-center mt-4 mb-2">
                    <a :href="downloadUrl" @click="showDownload = false" download class="exportBtn">{{$t('clickForDownload')}}</a>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
    <DatatableV2 :displayTitle="false" :flat="true" :url="apiUrl" :key="currentKey" ref="datatableOpe" />
    </v-col>
</v-row>
</template>

<script>
import DatatableV2 from "@/components/DatatableV2";
import vDaterangePicker from '@/components/forms/inputs/v-daterange-picker.vue'
import GenericDataService from '@/services/GenericDataService'

export default {
    name: "DetailStatCanalComponent",
    components: {
        DatatableV2,
        vDaterangePicker
    },
    props:['canal'],
    data() {
        return {
            apiUrl: "/stats/list?canal="+this.canal,
            api: this.$store.state.auth.apiUrl,
            filtersMarketing: {},
            stats: {},
            currentKey: 0,
            statKey: 0,
            downloadUrl: '',
            showDownload: false,
            loading: false
        };
    },
    mounted() {
        this.initData();
    },
    methods: {
        launchExportSpec(type){
            this.loading = true;
            GenericDataService.postData('/stats/exportStatsSpec', {type : type, filters : this.filtersMarketing}).then((response)=> {
                this.loading = false
                if(response.data.data.directDownload){
                    this.downloadUrl = this.api + response.data.data.directDownload
                    this.showDownload = true;
                }
            }).catch((error) => {
                this.loading = false
            });
        },
        initData() {
            GenericDataService.getData("/stats/getStatsByCanal?canal="+this.canal).then((response) => {
            this.filtersMarketing = response.data.data.filters
            for (const key in this.filtersMarketing) {
                if (Object.prototype.hasOwnProperty.call(this.filtersMarketing, key)) {
                    const element = this.filtersMarketing[key];
                    if(element.type == 'select' && element.hasOwnProperty('selectDataUrl')){
                        GenericDataService.getData(element.selectDataUrl).then((response) => {
                            element.items = response.data.data
                        })
                    }
                }
            }
            this.stats = response.data.data.stats
        });
        },
        reloadData(){
            GenericDataService.postData('/stats/getStatsByCanal?canal='+this.canal, {filters : this.filtersMarketing}).then((response)=> {
                this.stats = response.data.data.stats
                this.statKey++
            })
        },
        launchExport(){
            this.loading = true;
            GenericDataService.postData('/stats/exportStatsByCanal', {stats : this.stats, filters : this.filtersMarketing, canal: this.canal}).then((response)=> {
                this.loading = false
                if(response.data.data.directDownload){
                    this.downloadUrl = this.api + response.data.data.directDownload
                    this.showDownload = true;
                }
            }).catch((error) => {
                this.loading = false
            });
        },
    }
}
</script>